.ql-toolbar.ql-snow {
    position: sticky;
    z-index: 100;
    background: #ccc;
    width: 100%;
    height: 2.5em;
    top: 4em;
}

.ql-editor p {
    font-size: 1.2em;
}

.ql-editor pre {
    font-size: 1.1em;
}
